import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "board"
};
const _hoisted_2 = {
  class: "board-item-img"
};
const _hoisted_3 = ["href"];
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "blog-title"
};
const _hoisted_6 = {
  class: "blog-date"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blogs, blog => {
    return _openBlock(), _createElementBlock("div", {
      class: "board-item",
      key: blog.id
    }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("a", {
      href: blog.url,
      target: "_blank"
    }, [_createElementVNode("img", {
      src: require(`@/assets/images/blog/${blog.img}`)
    }, null, 8, _hoisted_4)], 8, _hoisted_3)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", null, _toDisplayString(blog.name), 1)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("span", null, _toDisplayString(blog.date), 1)])]);
  }), 128))]);
}