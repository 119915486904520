export default {
  name: '',
  components: {},
  data: () => ({
    blogs: [{
      id: 1,
      name: "2023 새해 인사 덕담 배틀 이벤트",
      date: "2023-01-02",
      img: "01_battle-2.png",
      url: 'https://blog.naver.com/eco-global/222972598010'
    }, {
      id: 2,
      name: "2023 새해 복 많이 받으세요.",
      date: "2022-12-30",
      img: "02_happyNewYear.gif",
      url: 'https://blog.naver.com/eco-global/222970204690'
    }, {
      id: 3,
      name: "ECO NFT \"Battle\" 오픈 이벤트 \"크리스마스 스토리 배틀\"",
      date: "2022-12-23",
      img: "03_battle-1.png",
      url: 'https://blog.naver.com/eco-global/222963532714'
    }, {
      id: 4,
      name: "에코플레닛 \"대한민국 환경대전 ESG 공헌 대상\" 온라인 플렛폼 부분 대상 수상",
      date: "2022-12-12",
      img: "04_environment.jpg",
      url: 'https://blog.naver.com/eco-global/222953605409'
    }, {
      id: 5,
      name: "중요공지 > ESG Token lock up 진행",
      date: "2022-12-12",
      img: "eco-in-tree.png",
      url: 'https://blog.naver.com/eco-global/222953595193'
    }, {
      id: 6,
      name: "ESG Financial 백서 2.0 업데이트를 위한 ESG 토큰 배분 완료 공지",
      date: "2022-12-07",
      img: "pino.png",
      url: 'https://blog.naver.com/eco-global/222949014874'
    }, {
      id: 7,
      name: "ESG Financial 백서 2.0 업데이트를 위한 ESG 토큰 재배분 공지",
      date: "2022-12-07",
      img: "teteru.png",
      url: 'https://blog.naver.com/eco-global/222948798164'
    }, {
      id: 8,
      name: "에코 NFT, 마이닝워크와 \'생활 속 ESG 실천, 투자\' 위한 협약",
      date: "2022-12-02",
      img: "08_miningwalk.jpg",
      url: 'https://blog.naver.com/eco-global/222944172462'
    }]
  }),
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
};