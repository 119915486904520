const motions = require("../motions");
import BannerVideo from '../components/BannerVideo.vue';
import Diagram1 from '../components/Diagram1.vue';
import KindOfNFT from '../components/KindOfNFT.vue';
import Diagram2 from '../components/Diagram2.vue';
import FinancialTeam from '../components/FinancialTeam.vue';
import Partners from '../components/Partners.vue';
import EsgNews from '@/components/EsgNews.vue';
export default {
  name: '',
  components: {
    BannerVideo,
    Diagram1,
    KindOfNFT,
    Diagram2,
    FinancialTeam,
    Partners,
    EsgNews
  },
  data: () => ({}),
  setup() {
    return {
      motions
    };
  },
  created() {},
  mounted() {
    this.intoClassElement();
    console.log(process.env.ESG_FINANCIAL_URL);
  },
  unmounted() {},
  methods: {
    intoClassElement() {
      this.$store.commit('intoClass1', this.$refs.home);
      this.$store.commit('intoClass2', this.$refs.about);
      this.$store.commit('intoClass3', this.$refs.ecoNft);
      this.$store.commit('intoClass4', this.$refs.zeroQuest);
      this.$store.commit('intoClass5', this.$refs.team);
      this.$store.commit('intoClass6', this.$refs.partner);
      this.$store.commit('intoClass7', this.$refs.news);
    }
  }
};