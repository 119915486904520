export const mainTitle = {
  initial: {
    y: -40,
    opacity: 0,
  },
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 100,
    }
  }
}
export const underLine = {
  initial: {
    x: -10,
    opacity: 0
  },
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 300,
    }
  }
}
export const subTitle = {
  initial: {
    y: -30,
    opacity: 0
  },
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 300,
    }
  }
}

export const text1_about = {
  initial: {
    x: -100,
    opacity: 0,
  },
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 100,
    }
  }
}
export const text2_about = {
  initial: {
    x: 100,
    opacity: 0,
  },
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 200,
    }
  }
}
export const text3_about = {
  initial: {
    x: -100,
    opacity: 0,
  },
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 300,
    }
  }
}

export const box1 = {
  initial: {
    opacity: 0
  },
  visibleOnce: {
    opacity: 1,
    transition: {
      delay: 200,
      duration: 400,
    }
  }
}

export const dia1 = {
  initial: {
    opacity: 0
  },
  visibleOnce: {
    opacity: 1,
    transition: {
      delay: 100,
    }
  }
}
export const dia2 = {
  initial: {
    opacity: 0
  },
  visibleOnce: {
    opacity: 1,
    transition: {
      delay: 200,
    }
  }
}
export const dia3 = {
  initial: {
    opacity: 0
  },
  visibleOnce: {
    opacity: 1,
    transition: {
      delay: 300,
    }
  }
}
export const dia4 = {
  initial: {
    opacity: 0
  },
  visibleOnce: {
    opacity: 1,
    transition: {
      delay: 400,
    }
  }
}
export const dia5 = {
  initial: {
    opacity: 0
  },
  visibleOnce: {
    opacity: 1,
    transition: {
      delay: 500,
    }
  }
}
export const dia6 = {
  initial: {
    opacity: 0
  },
  visibleOnce: {
    opacity: 1,
    transition: {
      delay: 600,
    }
  }
}
export const dia7 = {
  initial: {
    opacity: 0
  },
  visibleOnce: {
    opacity: 1,
    transition: {
      delay: 700,
    }
  }
}
export const dia8 = {
  initial: {
    opacity: 0
  },
  visibleOnce: {
    opacity: 1,
    transition: {
      delay: 800,
    }
  }
}
export const dia9 = {
  initial: {
    opacity: 0
  },
  visibleOnce: {
    opacity: 1,
    transition: {
      delay: 900,
    }
  }
}






export const box2 = {
  initial: {
    x: 40,
    opacity: 0,
  },
  visibleOnce: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 100,
      duration: 400,
    }
  }
}

export const mainTitle_v = {
  initial: {
    y: -40,
    opacity: 0,
  },
  visibleOnce: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 100,
    }
  }
}
export const subTitle_v = {
  initial: {
    y: -30,
    opacity: 0
  },
  visibleOnce: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 300,
    }
  }
}



export const cir1 = {
  initial: {
    x: -200,
    opacity: 0
  },
  visibleOnce: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 100,
    }
  }
}
export const cir2 = {
  initial: {
    x: 200,
    opacity: 0
  },
  visibleOnce: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 200,
    }
  }
}
export const cir3 = {
  initial: {
    x: -200,
    opacity: 0
  },
  visibleOnce: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 300,
    }
  }
}

export const port1 = {
  initial: {
    x: 0,
    opacity: 0
  },
  visibleOnce: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 300,
    }
  }
}
export const port2 = {
  initial: {
    x: 0,
    opacity: 0
  },
  visibleOnce: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 400,
    }
  }
}
export const port3 = {
  initial: {
    x: 0,
    opacity: 0
  },
  visibleOnce: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 500,
    }
  }
}
export const port4 = {
  initial: {
    x: 0,
    opacity: 0
  },
  visibleOnce: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 600,
    }
  }
}
export const port5 = {
  initial: {
    x: 0,
    opacity: 0
  },
  visibleOnce: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 700,
    }
  }
}
export const port6 = {
  initial: {
    x: 0,
    opacity: 0
  },
  visibleOnce: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 800,
    }
  }
}

export const contact1 = {
  initial: {x: 200, opacity: 0},
  visibleOnce: {x: 0, opacity: 1, transition: {delay: 100,}}
}
export const contact2 = {
  initial: {x: 200, opacity: 0},
  visibleOnce: {x: 0, opacity: 1, transition: {delay: 200,}}
}
export const contact3 = {
  initial: {x: 200, opacity: 0},
  visibleOnce: {x: 0, opacity: 1, transition: {delay: 300,}}
}
export const contact4 = {
  initial: {x: 200, opacity: 0},
  visibleOnce: {x: 0, opacity: 1, transition: {delay: 400,}}
}
export const contact5 = {
  initial: {x: 200, opacity: 0},
  visibleOnce: {x: 0, opacity: 1, transition: {delay: 500,}}
}
export const contact6 = {
  initial: {x: 200, opacity: 0},
  visibleOnce: {x: 0, opacity: 1, transition: {delay: 600,}}
}

export const contact_t1 = {
  initial: {x: 0, opacity: 0},
  visibleOnce: {x: 0, opacity: 1, transition: {delay: 100,}}
}
export const contact_t2 = {
  initial: {x: 0, opacity: 0},
  visibleOnce: {x: 0, opacity: 1, transition: {delay: 200,}}
}
export const contact_t3 = {
  initial: {x: 0, opacity: 0},
  visibleOnce: {x: 0, opacity: 1, transition: {delay: 300,}}
}
export const contact_t4 = {
  initial: {x: 0, opacity: 0},
  visibleOnce: {x: 0, opacity: 1, transition: {delay: 400,}}
}
export const contact_t5 = {
  initial: {x: 0, opacity: 0},
  visibleOnce: {x: 0, opacity: 1, transition: {delay: 500,}}
}
export const contact_t6 = {
  initial: {x: 0, opacity: 0},
  visibleOnce: {x: 0, opacity: 1, transition: {delay: 600,}}
}

export const send = {
  initial: {x: 0, opacity: 0},
  visibleOnce: {x: 0, opacity: 1, transition: {delay: 700,}}
}

