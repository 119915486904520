import { createStore } from 'vuex'

export default createStore({
  state: {
    home: "",
    about: "",
    ecoNft: "",
    zeroQuest: "",
    team: "",
    partner: "",
    news: "",
  },
  getters: {
  },
  mutations: {
    intoClass1(state, name) {
      state.home = name
    },
    intoClass2(state, name) {
      state.about = name
    },
    intoClass3(state, name) {
      state.ecoNft = name
    },
    intoClass4(state, name) {
      state.zeroQuest = name
    },
    intoClass5(state, name) {
      state.team = name
    },
    intoClass6(state, name) {
      state.partner = name
    },
    intoClass7(state, name) {
      state.news = name
    }
  },
  actions: {
  },
  modules: {
  }
})
