import WhitePaper from '@/components/header/WhitePaper.vue';
import ServiceMap from '@/components/header/ServiceMap.vue';
import Locale from '@/components/header/Locale.vue';
import MainPage from '@/views/MainPage.vue';
export default {
  name: '',
  components: {
    ServiceMap,
    Locale,
    WhitePaper,
    MainPage
  },
  data: () => ({
    menu: [{
      id: 0,
      name: "header1"
    }, {
      id: 1,
      name: "header2"
    }, {
      id: 2,
      name: "header3"
    }, {
      id: 3,
      name: "header4"
    }, {
      id: 4,
      name: "header5"
    }, {
      id: 5,
      name: "header6"
    }],
    scrollTerm: [200, 400, 600, 800, 1000, 1200],
    hoverColor: "#3d6fe3"
  }),
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    scrollToHome() {
      const home = this.$store.state.home;
      home.scrollIntoView({
        behavior: 'smooth'
      });
    },
    scrollToElement(name) {
      const about = this.$store.state.about;
      const ecoNft = this.$store.state.ecoNft;
      const zeroQuest = this.$store.state.zeroQuest;
      const team = this.$store.state.team;
      const partner = this.$store.state.partner;
      const news = this.$store.state.news;
      if (name === 'header1') {
        about.scrollIntoView({
          behavior: 'smooth'
        });
      } else if (name === 'header2') {
        ecoNft.scrollIntoView({
          behavior: 'smooth'
        });
      } else if (name === 'header3') {
        zeroQuest.scrollIntoView({
          behavior: 'smooth'
        });
      } else if (name === 'header4') {
        team.scrollIntoView({
          behavior: 'smooth'
        });
      } else if (name === 'header5') {
        partner.scrollIntoView({
          behavior: 'smooth'
        });
      } else {
        news.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }
};