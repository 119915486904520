export default {
  name: '',
  components: {},
  data: () => ({
    partners: [{
      id: 1,
      image: "01_wmu.png"
    }, {
      id: 2,
      image: "02_kabc.png"
    }, {
      id: 3,
      image: "03_WaltonChain.png"
    }, {
      id: 4,
      image: "04_ENPGAMES.png"
    }, {
      id: 5,
      image: "05_miningWalk.png"
    }, {
      id: 6,
      image: "06_Dcoin.png"
    }, {
      id: 7,
      image: "07_pingstone.png"
    }, {
      id: 8,
      image: "08_SKYplay.png"
    }, {
      id: 10,
      image: "10_F1soft.png"
    }, {
      id: 11,
      image: "11_AniAsso.png"
    }, {
      id: 12,
      image: "12_TETERU.png"
    }, {
      id: 13,
      image: "13_KPOWER.png"
    }, {
      id: 14,
      image: "14_RNP_LAB.png"
    }, {
      id: 15,
      image: "15_ecomagic.png"
    }, {
      id: 16,
      image: "16_prix.png"
    }]
  }),
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
};