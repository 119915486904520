export default {
  name: '',
  components: {},
  data: () => ({
    over1: false,
    over2: false,
    over3: false
  }),
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
};