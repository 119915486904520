import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "partners"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partners, item => {
    return _openBlock(), _createElementBlock("div", {
      class: "partners-item",
      key: item.id
    }, [_createElementVNode("img", {
      src: require(`@/assets/images/partners/${item.image}`)
    }, null, 8, _hoisted_2)]);
  }), 128))]);
}