export default {
  name: '',
  components: {},
  data: () => ({
    logos: [{
      name: "telegram",
      src: new URL("@/assets/images/sns01_off.png", import.meta.url).href,
      src_off: new URL("@/assets/images/sns01_off.png", import.meta.url).href,
      src_on: new URL("@/assets/images/sns01_on.png", import.meta.url).href,
      href: "https://twitter.com/esg_financial/"
    }, {
      name: "twitter",
      src: new URL("@/assets/images/sns02_off.png", import.meta.url).href,
      src_off: new URL("@/assets/images/sns02_off.png", import.meta.url).href,
      src_on: new URL("@/assets/images/sns02_on.png", import.meta.url).href,
      href: "https://t.me/eco_global"
    }, {
      name: "blog",
      src: new URL("@/assets/images/sns03_off.png", import.meta.url).href,
      src_off: new URL("@/assets/images/sns03_off.png", import.meta.url).href,
      src_on: new URL("@/assets/images/sns03_on.png", import.meta.url).href,
      href: "https://blog.naver.com/eco-global"
    }, {
      name: "medium",
      src: new URL("@/assets/images/sns04_off.png", import.meta.url).href,
      src_off: new URL("@/assets/images/sns04_off.png", import.meta.url).href,
      src_on: new URL("@/assets/images/sns04_on.png", import.meta.url).href,
      href: "https://esgfinancial.medium.com/"
    }]
  }),
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
};