const motions = require("../motions");
export default {
  name: '',
  components: {},
  data: () => ({}),
  setup() {
    return {
      motions
    };
  },
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
};