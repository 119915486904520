import axios from 'axios';
export default {
  date: () => ({}),
  methods: {
    whitePaperDown(lang) {
      axios.get(`/data/ESGFinancialWhitePaperV2${lang}.pdf`, {
        responseType: "blob"
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ESGFinancialWhitePaperV2${lang}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      }).catch(exception => {
        console.log(exception);
        alert("파일 다운로드 실패");
      });
    }
  }
};